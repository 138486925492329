body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  padding: 1rem;
}

#detailedCharts {
  overflow-x: scroll;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product {
  border: 1px #404040 solid;
  margin: 1rem;
}
.product img {
  width: 100%;
  max-width: 200px;
}
.product-info {
  padding: 1rem;
}

.background {
  background-color: #f1f3fe;
}
